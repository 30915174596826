import React from "react";
import ProductHero from "../../components/products/hero";
import TwoColumns from "../../components/products/banner";
import Container from "../../layout/container";
import Benefits from "../../components/benefit";
import Meta from "../../components/common/meta";
const data = [
  {
    id: 1,
    title: "知識を共有し、エキスパートへの依存を減らす",
    description:
      "よく使う手順のためのガイドを備えたランブックを作成し、従来は対象分野のエキスパートによって実行されていたタスクを、開発者や他の従業員が安全に実行できるようにします。",
    isReverse: true,
    image: "/products/requests/one.webp",
  },
  {
    id: 2,
    title: "エンジニアリングに使える時間を最大化",
    description:
      "繰り返しの作業を減らすことで、エンジニアはビジネスを改善する価値の高い作業（新しいプラットフォームの提供、スケーリングとパフォーマンスの作業、新しいテクノロジーの分析など）に集中できるようになります。",
    isReverse: false,
    image: "/products/requests/two.png",
  },
  {
    id: 3,
    title: "組織全体でより多くを達成可能に",
    description:
      "Opsの支援に頼ることなく、他のチームが安全に行動を起こし、タスクを完了することができるようにします。",
    isReverse: true,
    image: "/products/requests/three.webp",
  },
];
const heroData = {
  title: "より効率的な作業方法を普及させます",
  description:
    "Runbook Automationを使用して、Opsアクションへのセルフサービスアクセスを強化します",
  bg: "/products/requests/banner.svg",
};
const benefitData = {
  title: "エンドユーザーを適切なタイミングで自動化に組み込む",
  cards: [
    {
      id: 1,
      title: "労力とチケット数の削減",
      description:
        "プロセスを自動化し、要求者がセルフサービスアクションとして利用できるようにすることで、チケットを迅速にクローズし、チケットの乱発を防止します。",
    },
    {
      id: 2,
      title: "運用チームの生産性向上",
      description:
        "エンドユーザーやスタッフに安全に操作をまかせ、エキスパートへのエスカレーションを減らすことができます。",
    },
    {
      id: 3,
      title: "イノベーション能力の向上",
      description:
        "リアルタイムオペレーションにより、運用チームは、ソフトウェアの継続的デリバリーや価値の高いソリューションのエンジニアリングなど、より迅速な対応が求められる業務のサポートに回ることができます。",
    },
  ],
};
const defaultMeta = {
  title:
    "サービスリクエストの自動化｜Rundeck正規販売代理店 DXable",
  description:
    "Rundeckは、インシデント復旧時間とコストの大幅削減を実現するランブックオートメーションツールです。スクリプトやcronによる定型作業のランブック登録で、誰でも安全にタスクを実行できます。",
  url: "/service-requests",
};
const ServiceRequests = () => {
  return (
    <Container>
      <Meta meta={defaultMeta}></Meta>
      <ProductHero {...heroData} />
      <div className="md:px-8">
        <Benefits data={benefitData}></Benefits>
        <div className="p-4 md:p-0">
          {data.map((sec) => (
            <TwoColumns
              key={sec.id}
              isColReverse={sec.isReverse}
              image={sec.image}
              title={sec.title}
              description={sec.description}
            />
          ))}
        </div>
      </div>
    </Container>
  );
};
export default ServiceRequests;
